import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";

class App extends React.Component {
	render = () => {
		return (
			<div className="card">
				<div className="header">
					<div className="logo">
						<a href=".">
							<img src={require('./assets/logo.jpg')} alt="logo" height={50}/>
						</a>
					</div>
					<div className="social">
						<a href="https://www.facebook.com/profile.php?id=100092820652349" title="Facebook" target="_blank" rel="noopener noreferrer">
							<IconFacebook className="icon" />
						</a>
					</div>
				</div>
				<div className="content">
					<div className="title-holder">
						<h1>Website under construction.</h1>
						<p>Get ready for the change. Please check back to know more.</p>
					</div>
				</div>
			</div>
		);
	}
}

export default App;